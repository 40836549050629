import React from "react";
import PropTypes from "prop-types";

const NoteEnGB = ({ shop }) =>
	shop === "en-GB" && (
		<p>
			*Discount is in comparison to hotels's room rack rates. The discount is on hotels (or
			the hotel proportion of any package you book) only. The discount does not apply to any
			other travel service you book. The travel services we make available on our website
			fluctuate with demand and have limited availability and therefore prices may change to
			either less or more than the displayed prices at short notice, as prices are updated
			frequently. Please check the exact price and conditions (including any finalised
			discount) applicable for your chosen hotel or package where this includes the cost of a
			hotel, at the time of booking.
		</p>
	);

NoteEnGB.propTypes = { shop: PropTypes.string };

export default React.memo(NoteEnGB);
