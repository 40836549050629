import { STRATEGY } from "app/constants";
import { getStore } from "app/configureStore";
import env from "app/utils/env";
import { isAuthenticated } from "app/pages/Auth/authSelectors";

export const PRODUCT_EXPIRATION_DAY_LIMIT = 30;

export const IS_DEFAULT_LISTING_VIEW_LIST = false;

export const shouldGetProfileAtPayment = () => {
	const state = getStore().getState();
	const strategy = state.partner.strategy;
	return strategy !== STRATEGY.TRANSACTION_FIRST || isAuthenticated(state);
};

export const getProfileApiUrl = () => {
	return `${env("USER_AUTH_API_URL")}/me`;
};

export const SECOND_LABEL_BACKGROUND_COLOR = "#fff";
export const SECOND_LABEL_BORDER_COLOR = "#2B383F";
export const SECOND_LABEL_TEXT_COLOR = "#2B383F";

export const THIRD_LABEL_BACKGROUND_COLOR = "#fff";
export const THIRD_LABEL_BORDER_COLOR = "#2B383F";
export const THIRD_LABEL_TEXT_COLOR = "#2B383F";

export const FIRST_MERCHANDISING_POSITION = 0;
export const SECOND_MERCHANDISING_POSITION = 3;
export const THIRD_MERCHANDISING_POSITION = 7;
export const FOURTH_MERCHANDISING_POSITION = 11;

export const OAUTH_PARAMS = {};
