import React from "react";
import { FormattedMessage } from "react-intl";
import "./AuthFooter.scss";

const AuthFooter = () => {
	return (
		<div className="auth-footer">
			<FormattedMessage id="home.auth.form.note.1">
				{translatedText => <div className="auth-footer__item">{translatedText}</div>}
			</FormattedMessage>

			<FormattedMessage id="home.auth.form.note.2">
				{translatedText => <div className="auth-footer__item">{translatedText}</div>}
			</FormattedMessage>
		</div>
	);
};

export default React.memo(AuthFooter);
